globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"Gtjw3kF5seU74q3NvCcAU"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import * as Sentry from "@sentry/nextjs";

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  
  // release: process.env.NEXT_PUBLIC_SENTRY_RELEASE_VERSION,// "my-project-name@" + process.env.npm_package_version,
  
  environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,// uat,production,staging

  // Replay may only be enabled for the client-side
  integrations: [Sentry.replayIntegration()],
  telemetry: false,  // Disable telemetry

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  // ...

  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps

  autoSessionTracking: true,  // Enables tracking of sessions

});
